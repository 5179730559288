<template>
  <div style="margin-bottom: 20px" class="py-4 mx-3">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-card class="d-flex align-center rounded-lg pa-4" height="60" flat>
          <v-text-field
            hide-details
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('writeWhatYouSearch')"
            outlined
            dense
          >
          </v-text-field>
          <v-btn tile color="primary" @click="back()" class="rounded-xl ma-2">
            {{ $t("back") }}
            <v-icon right> mdi-arrow-left </v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="n in visiblePages"
        :key="n.categoryGuid"
        cols="12"
        lg="2"
        md="3"
        sm="12"
        :search="search"
      >
        <v-card
          outlined
          height="210"
          :class="
            n.isActive
              ? ''
              : $vuetify.theme.dark
              ? 'category-unactive-dark'
              : 'category-unactive-light'
          "
        >
          <v-card-title
            class="pa-2"
            dir="auto"
            style="word-break: break-word; height: 70px; overflow: hidden"
            :title="n.categoryName"
          >
            {{ n.categoryName }}
          </v-card-title>
          <v-card-text class="pa-2" dir="auto">
            <div
              style="word-break: break-word; height: 50px; overflow: hidden"
              :title="n.categoryDescription"
            >
              {{ n.categoryDescription }}
            </div>
            <div
              v-if="n.studySubject"
              :title="n.studySubject.subjectName"
              style="
                word-break: break-word;
                height: 25px;
                overflow: hidden;
                font-weight: bold;
              "
            >
              {{ n.studySubject.subjectName }}
            </div>
            <div v-else><br /></div>
          </v-card-text>
          <v-card-actions class="pa-1 px-2">
            <v-btn
              :title="$t('show')"
              icon
              @click="openCategory(n.categoryGuid)"
            >
              <v-icon color="primary" dark>mdi-file-eye-outline</v-icon>
            </v-btn>

            <v-btn :title="$t('edit')" icon @click="openEditDialog(n)">
              <v-icon color="primary" dark>mdi-file-edit</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :title="$t('delete')"
              icon
              @click="deleteCategory(n.categoryGuid)"
            >
              <v-icon color="grey" dark>mdi-delete</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="12">
        <v-pagination
          v-if="categories[0]"
          v-model="page"
          :length="Math.ceil(categoriesFilter.length / perPage)"
          :total-visible="perPage"
        ></v-pagination>
      </v-col>
    </v-row>

    <v-fab-transition v-if="isInRole(2)">
      <v-btn
        color="secondary"
        fab
        large
        elevation="6"
        fixed
        bottom
        :left="$vuetify.rtl"
        :right="!$vuetify.rtl"
        class="v-btn--example"
        :class="$store.state.drawerMessage ? 'ml-16' : ''"
        :style="!$vuetify.rtl ? 'right:80px' : ''"
        @click="dialog = true"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-dialog
      transition="dialog-bottom-transition"
      persistent
      max-width="600"
      v-model="dialog"
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark>{{
          $t("exam.addCategory")
        }}</v-toolbar>
        <v-card-text class="mt-4">
          <v-autocomplete
            v-if="categoryModel.categoryGuid == null"
            v-model="categoryModel.subjectGuid"
            :items="subjects"
            outlined
            chips
            small-chips
            :label="$t('degrees.selectSubject')"
            item-text="subjectName"
            item-value="subjectGuid"
          ></v-autocomplete>
          <v-text-field
            :label="$t('exam.categoryName')"
            :placeholder="$t('exam.categoryNamePlaceholder')"
            outlined
            v-model="categoryModel.categoryName"
            :rules="[required]"
          ></v-text-field>
          <v-text-field
            :label="$t('exam.categoryDescription')"
            :placeholder="$t('exam.categoryDescription')"
            outlined
            v-model="categoryModel.categoryDescription"
          ></v-text-field>
          <v-checkbox
            v-model="categoryModel.isActive"
            :label="$t('exam.activeStatus')"
          >
          </v-checkbox>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="dialog = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            :disabled="!categoryModel.categoryName"
            color="primary darken-1"
            @click="addUpdateCategory()"
            :loading="addLoad"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      categories: [],
      search: "",
      subjects: [],
      dialog: false,
      addLoad: false,
      perPage: 18,
      page: 1,
      categoryModel: {
        categoryGuid: null,
        subjectGuid: null,
        categoryName: null,
        isActive: true,
        categoryDescription: null,
      },
    };
  },
  created() {
    this.getBankCategories();
    this.getLecTech();
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.resetCategoryModel();
      }
    },
  },
  computed: {
    visiblePages() {
      return this.categoriesFilter.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
    categoriesFilter() {
      const data = this.categories;
      this.page = 1;
      if (this.search) {
        return data.filter((item) => this.search
            .toLowerCase()
            .split(" ")
            .every(
              (v) => (item.studySubject &&
                  item.studySubject.subjectName.toLowerCase().includes(v)) ||
                item.categoryName.toLowerCase().includes(v) ||
                (item.categoryDescription &&
                  item.categoryDescription.toLowerCase().includes(v))
            ));
      } else {
        return data;
      }
    },
  },
  methods: {
    getBankCategories() {
      this.overlay = true;
      this.axios
        .get(`Exams/QuestionsBankCategories?activeStatus=2`)
        .then((response) => {
          this.categories = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    getLecTech() {
      this.overlay = true;
      this.axios
        .get("Subjects/Get")
        .then((res) => {
          this.subjects = res.data.data.subjects;
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;
        });
    },
    addUpdateCategory() {
      this.addLoad = true;

      this.axios
        .post(
          `Exams/${
            this.categoryModel.categoryGuid == null
              ? "AddQuestionsBankCategory"
              : "UpdateQuestionsBankCategory"
          }`,
          this.categoryModel
        )
        .then((res) => {
          if (
            res.data.statusCode == 1 &&
            this.categoryModel.categoryGuid == null
          ) {
            this.openCategory(res.data.data.categoryGuid);
          } else {
            this.getBankCategories();
          }

          // this.getBankCategories();
          this.resetCategoryModel();

          this.dialog = false;
        })
        .catch((e) => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: e,
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.addLoad = false;
        });
    },
    openEditDialog(item) {
      this.categoryModel = null;
      this.categoryModel = { ...item };
      this.dialog = true;
    },
    openCategory(categoryGuid) {
      this.$router.push({
        path: `/showQuestionsBank=${categoryGuid}`,
        params: { id: categoryGuid },
      });
    },
    deleteCategory(categoryGuid) {
      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            this.axios
              .delete(
                `Exams/DeleteQuestionsBankCategory?categoryGuid=${categoryGuid}`,
                this.categoryModel
              )
              .then(() => {
                this.getBankCategories();
              })
              .catch((e) => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: e,
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              })
              .finally(() => {});
          }
        });
    },
    back() {
      this.$router.go(-1);
    },
    resetCategoryModel() {
      setTimeout(() => {
        this.categoryModel = {
          categoryGuid: null,
          subjectGuid: null,
          categoryName: null,
          isActive: true,
          categoryDescription: null,
        };
      }, 500);
    },
  },
};
</script>

<style>
.category-unactive-dark {
  background: rgba(255, 191, 0, 0.03);
  background-image: repeating-linear-gradient(
    -45deg,
    #272727,
    #242432 10px,
    #00000045 0,
    #242432 20px
  );
}
.category-unactive-light {
  background: rgba(255, 191, 0, 0.03);
  background-image: repeating-linear-gradient(
    -45deg,
    rgb(238 238 238),
    rgba(0, 0, 0, 0.02) 10px,
    transparent 0,
    transparent 20px
  );
}
</style>